<template>
  <div class="course-wrapper">
    <div class="course-wrapper-title">
      <span>一日流程列表</span>
    </div>

    <div class="course-wrapper-body daily_process_list">
      <div class="search_main">
        <el-input
          class="search_main_title"
          v-model="searchTitle"
          placeholder="流程名称"
          clearable
        >
        </el-input>
        <el-date-picker
          class="search_main_date"
          v-model="searchUpdateDate"
          type="date"
          placeholder="更新时间"
          clearable
        >
        </el-date-picker>
        <el-date-picker
          class="search_main_date"
          v-model="searchCreateDate"
          type="date"
          placeholder="创建时间"
          clearable
        >
        </el-date-picker>
        <el-button @click="handleButton('search')" type="primary"
          >查询</el-button
        >
        <el-button @click="handleButton('reset')">重置</el-button>
        <el-button
          style="float: right"
          v-if="isAdd"
          class="search_main_button_plus"
          @click="searchButtonPlus"
          type="primary"
          icon="el-icon-plus"
          >新建流程</el-button
        >
      </div>

      <!-- <div class="search_main">
        
      </div> -->

      <el-table class="table_data" :data="tableData" border ><!--v-loading="tableLoading"-->
        <el-table-column
          prop="prop"
          label="序号"
          type="index"
          width="60"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="templateName" label="一日流程名称">
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="创建时间"
          width="160"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="updateTime"
          label="更新时间"
          width="160"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="userName"
          label="创建人"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="prop" label="操作" width="140" align="center">
          <template slot-scope="scope">
            <el-button @click="handleClickUpdate(scope)" type="text" v-if="isEdit"
              >编辑</el-button
            >
            <span class="table_data_button_divider" v-if="isEdit">|</span>
            <el-button @click="handleClickDelete(scope)" type="text" v-if="isDelete"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="course-wrapper-footer">
        <div class="footer-left">
          显示第{{(pagination.current - 1) * pagination.size + 1}}-{{(pagination.current - 1) * pagination.size + tableData.length}}条记录，共{{pagination.total}}条记录
        </div>
        <el-pagination
          class="pagination"
          @size-change="paginationSizeChange"
          @current-change="paginationCurrentChange"
          :layout="pagination.layout"
          :total="pagination.total"
          align="right"
          background
        >
        </el-pagination>
      </div>

    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import tool from '@/utils/tool';
import { getPermissionButton } from '@/utils/getUrlPermissionButtons';
export default {
  name: 'dailyProcessList',
  components:{
  },
  data() {
    return {
      searchTitle: '',
      searchUpdateDate: '',
      searchCreateDate: '',
      tableData: [],
      pagination: {
        layout: 'total,sizes,prev,pager,next,jumper',
        total: 110,
        current: 1,
        size: 10
      },
      confirmData: {},
      tableLoading: false, // 表格loading
      pageLoading: null, //页面loading

      isEdit: false, // 编辑权限
      isAdd: false, // 新增权限
      isDelete: false, // 删除权限
      isView: false, // 查看权限
      permissionButtonList: [], // 权限列表
    };
  },
  computed: {
    ...mapGetters(['permissions']),
    ...mapState({
      menuList: (state) => state.common.menuList,
    }),
  },
  watch: {
    permissionButtonList: {
      handler(val) {
        if (val) {
          this.isEdit = val.indexOf('dailyProcess:update') != -1;
          this.isAdd = val.indexOf('dailyProcess:save') != -1;
          this.isDelete = val.indexOf('dailyProcess:delete') != -1;
          this.isView = val.indexOf('dailyProcess:view') != -1;
        }
      },
      deep: true,
    },
    menuList: {
      handler(val) {
        if (val) {
          this.getPermissionButton(this.$route.path, (data) => {
            this.permissionButtonList = data;
          });
        }
      },
      deep: true,
    },
  },
  created() {
    // menulist一直在时这个方法不可缺少
    this.getPermissionButton(this.$route.path, (data) => {
      this.permissionButtonList = data;
    });
    // this.tableLoading = true;
    this.pageLoading = this.$loading({
      lock: true,
      fullscreen: true,
      text: '加载中',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)',
      customClass: "loadingStyle"
    });
    this.searchLinkList();
  },
  methods: {
    getPermissionButton,
    searchButtonPlus() {
      this.$router.push({
        path: '/dailyProcessList/insertTemplate/insert'
      });
    },
    /**
     * 编辑
     */
    handleClickUpdate(e) {
      let { id } = e.row;
      this.$router.push({
        path: '/dailyProcessList/insertTemplate/' + id
      });
    },
    /**
     * 删除
     */
    handleClickDelete(scope) {
      console.log(scope);
      let self = this;
      this.confirm({
        success() {
          self.$api.deleteDayLink(scope.row.id)
            .then(result => {
              let { code, data, msg } = result.data;
              if (code) {
                self.$message.warning(msg);
                return;
              }
              // self.tableLoading = true;
              self.pageLoading = self.$loading({
                lock: true,
                fullscreen: true,
                text: '加载中',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)',
                customClass: "loadingStyle"
              });
              self.searchLinkList();
              self.$message.success(data);
            })
            .catch(err => {
              console.log(err);
            });
        },
        fail() {

        }
      });
    },
    /**
     * 查询一日流程列表
     */
    searchLinkList(params = {}) {
      let { current, size } = this.pagination;
      this.$api
        .searchLinkList({
          current,
          size,
          ...params
        })
        .then(result => {
          // this.tableLoading = false;
          this.pageLoading.close()
          let { code, data } = result.data;
          if (code) {
            return;
          }
          let { records, current, total } = data;
          this.pagination.total = total;
          this.pagination.current = current;
          this.tableData = records;
        })
        .catch(err => {
          // this.tableLoading = false;
          this.pageLoading.close()
          console.log(err);
        });
    },
    /**
     * 每页条数上限改变时
     */
    paginationSizeChange(value) {
      this.pagination.size = value;
      // this.tableLoading = true;
      this.pageLoading = this.$loading({
        lock: true,
        fullscreen: true,
        text: '加载中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
        customClass: "loadingStyle"
      });
      this.searchLinkList();
    },
    /**
     * 页数改变时
     */
    paginationCurrentChange(value) {
      this.pagination.current = value;
      // this.tableLoading = true;
      this.pageLoading = this.$loading({
        lock: true,
        fullscreen: true,
        text: '加载中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
        customClass: "loadingStyle"
      });
      this.searchLinkList();
    },
    /**
     * 操作按钮
     */
    handleButton(type) {
      switch (type) {
        case 'search': {
          let { searchTitle, searchUpdateDate, searchCreateDate } = this;
          // debugger;
          let params = {};
          if (searchTitle) {
            params.templateName = searchTitle;
          }
          if (searchUpdateDate) {
            let { startDate, endDate } = tool.formatDataStartEnd(
              searchUpdateDate
            );
            params.updateStartTime = startDate;
            params.updateEndTime = endDate;
          }
          if (searchCreateDate) {
            let { startDate, endDate } = tool.formatDataStartEnd(searchCreateDate);
            params.createStartTime = startDate;
            params.createEndTime = endDate;
          }
          // this.tableLoading = true;
          this.pageLoading = this.$loading({
            lock: true,
            fullscreen: true,
            text: '加载中',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)',
            customClass: "loadingStyle"
          });
          this.searchLinkList(params);
          break;
        }

        case 'reset':
          this.searchTitle = '';
          this.searchUpdateDate = '';
          this.searchCreateDate = '';
          // this.tableLoading = true;
          this.pageLoading = this.$loading({
            lock: true,
            fullscreen: true,
            text: '加载中',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)',
            customClass: "loadingStyle"
          });
          this.searchLinkList();
          break;
      }
    },

    confirm(data) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          data.success && data.success(data.data);
        })
        .catch(() => {
          data.fail && data.fail();
        });
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/css/common_c.scss";
.course-wrapper-body.daily_process_list {
  .search_main {
    $width: 150px;
    margin-bottom: 20px;
    &:after {
      display: block;
      content: '';
      height: 100%;
      clear: both;
      visibility: hidden;
    }
    .search_main_date {
      width: $width;
      margin-right: 20px;
    }
    .search_main_title {
      margin-right: 20px;
      width: 250px;
    }
    .search_main_button_plus {
      float: right;
      width: 120px;
    }
  }
  .table_data {
    .table_data_button_divider {
      display: inline-block;
      padding: 0 10px;
    }
  }
  .pagination {
    margin-top: 20px;
  }
}

.course-wrapper-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .footer-left {
    color: #7c7c7c;
    font-size: 14px;
  }
}
</style>
